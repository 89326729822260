import React from 'react'
import { PageProps, graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { MapDetailsQuery } from '@graphql-types'
import { css, useTheme } from '@emotion/react'
import MAPLayout from './_layout'
import { GatsbyImage } from 'gatsby-plugin-image'
import ReactMarkdown from 'react-markdown'
import CourseCurriculum from '@components/CourseCurriculum'
import { ScrollAnimation } from '@components/ScrollAnimation'

const MAPDetailsPage = (props: PageProps<MapDetailsQuery>) => {
	const { t } = useTranslation()
	const theme = useTheme()
	const course = props.data.course!
	return (
		<MAPLayout course={course}>
			<h2>{t('courses:common.curriculum')}</h2>
			<h3
				css={css`
					color: ${theme.highlightColor};
				`}>
				{course.slogan}
			</h3>

			{course.details?.map(
				(detail, i) =>
					detail && (
						<CourseCurriculum key={i}>
							<ScrollAnimation
								animateIn={
									i % 2 === 0 ? 'fadeInRight' : 'fadeInLeft'
								}>
								<GatsbyImage
									image={
										detail.imageSharp?.childImageSharp
											?.gatsbyImageData
									}
									aria-hidden={true}
									alt={detail.title!}
									css={css`
										filter: brightness(1.5)
											hue-rotate(205deg);
									`}
								/>
							</ScrollAnimation>
							<div>
								<h2>{detail.title}</h2>
								<h4
									css={css`
										color: ${theme.highlightColor};
									`}>
									{detail.mode}
								</h4>
								<ReactMarkdown children={detail.description!} />
								<div className='logos'>
									{detail.iconsSharp?.map((icon, j) => (
										<ScrollAnimation animateIn={'fadeInUp'}>
											<GatsbyImage
												image={
													icon.childImageSharp
														?.gatsbyImageData
												}
												alt={
													detail.icons?.[j]?.meta
														.title
												}
											/>
										</ScrollAnimation>
									))}
								</div>
							</div>
						</CourseCurriculum>
					)
			)}
		</MAPLayout>
	)
}

export default MAPDetailsPage

export const query = graphql`
	query MAPDetails($language: String!) {
		...TranslationFragment
		...MAPCoursePageFragment
	}
`
